import { Link } from '@medely/web-components';
import React, { ReactElement } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { MedelyLogo } from '@medely/web-components/icons';
import { useTheme } from '@medely/web-components/themes';
import { Box, Heading, Text } from '@medely/ui-kit';

const DefaultView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Box py={3}>
      <Heading size="xs">Thank you for your interest in joining Medely!</Heading>
    </Box>
    <Box pb={3}>
      <Text size="m">
        At this time, your application has not been accepted because you do not meet our
        requirements for work experience and/or education.
      </Text>
    </Box>
    <Box pb={3}>
      <Text size="m">
        For details about the specific requirements for the position you applied for, please review
        the relevant article in our{' '}
        <Link
          href="https://help.medely.com/en/collections/1942707-rn-allied-position-descriptions"
          target="_blank"
        >
          Help Center
        </Link>
        .
      </Text>
    </Box>
    <Box pb={3}>
      <Text size="m">
        Once you feel that you meet the requirements, we welcome you to re-apply.
      </Text>
    </Box>
  </>
);

const NoMarketView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Box py={3}>
      <Heading size="xs">Thank you for signing up with Medely.</Heading>
    </Box>
    <Box pb={3}>
      <Text size="m">
        Medely is always expanding but right now, there are no available shifts for your position.
      </Text>
    </Box>
    <Box pb={3}>
      <Text size="m">
        We have saved your profile and Medely will email you once shifts are available to resume
        your sign-up process.
      </Text>
    </Box>
  </>
);

const LowIncrementalityView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Box py={3}>
      <Heading size="xs">Thank you for signing up with Medely.</Heading>
    </Box>
    <Box pb={3}>
      <Text size="m">
        Due to high demand, Medely is currently not accepting new sign-ups in your area.
      </Text>
    </Box>
    <Box pb={3}>
      <Text size="m">
        We have saved your profile and Medely will email you when sign-ups in your area reopens.
      </Text>
    </Box>
  </>
);

const ProArchivedView = (): ReactElement => {
  const { currentUser } = useCurrentUser();
  const theme = useTheme();

  // note: in PRO-2863, archive_reason will become an enum instead of a string
  switch (currentUser?.professional?.archive_reason) {
    case 'dormant_market':
    case 'unavailable_market':
      return <NoMarketView theme={theme} />;
    case 'low_incrementality_market':
      return <LowIncrementalityView theme={theme} />;
    default:
      return <DefaultView theme={theme} />;
  }
};

export default ProArchivedView;
