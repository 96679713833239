import { TaskCard } from '@medely/ui-kit';
import React from 'react';
import { config } from '@src/config';
import { useProNavigate } from '@src/contexts';
import { TaskCardLayout } from './TaskCard';

export const SetYourselfUp = () => {
  const { openUrl } = useProNavigate();
  const onClick = () => openUrl(config.help.setYourselfUpForSuccess);

  return (
    <TaskCardLayout>
      <TaskCard
        iconProps={{ name: 'info-circle', color: 'primary' }}
        title="Set yourself up for success"
        body="Learn how to find and book shifts with Medely."
        onClick={onClick}
      />
    </TaskCardLayout>
  );
};
