import React, { Fragment } from 'react';
import { capitalize } from 'core/utils/text';
import { Typography, InfoBadge } from '@medely/ui-kit';
import { Box, Card, Divider } from '@medely/web-components';
import { ChevronRight } from '@medely/web-components/icons';
import ConditionFormFields from 'components/credentials/ConditionFormFields';
import { useMissingConditionForm } from 'components/credentials/conditionDialogs/MissingConditionForm';
import type { MissingConditionOption } from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import type { ICondition, IQualification } from '@medely/types';

interface IMissingConditionFormOptionProps {
  option: MissingConditionOption;
  onClick: (condition: ICondition, qualification: IQualification) => void;
}

interface IMissingSingleConditionQualificationStatus {
  option: MissingConditionOption;
  qualification: IQualification;
}

const MissingSingleConditionQualificationStatus = ({
  option,
  qualification,
}: IMissingSingleConditionQualificationStatus) => {
  const status = option.qualificationStatus[qualification.id];
  if (status === 'approved') {
    return <InfoBadge color="success" label="Approved" />;
  }
  if (status === 'rejected') {
    return <InfoBadge color="error" label="Rejected" />;
  }
  if (status === 'expired') {
    return <InfoBadge color="error" label="Expired" />;
  }
  if (option.willBeSatisfied || status === 'review') {
    return <InfoBadge color="info" label="Under Review" />;
  }
  return null;
};

const MissingSingleConditionFormOption = ({
  option,
  onClick,
}: IMissingConditionFormOptionProps) => {
  const title = option.condition.qualifications[0]?.long_name || option.description;

  const handleClick = () => {
    onClick(option.condition, option.condition.qualifications[0]);
  };

  return (
    <Card color="gray">
      <Box
        p={2}
        display="flex"
        alignItems="center"
        gap={2}
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <Box flex={1}>
          <Typography variant="body" size="m" color="text.primary">
            {capitalize(title)}
          </Typography>
          <Box mt={1}>
            {option.condition.qualifications.map((qualification) => (
              <Box
                key={String(qualification.id)}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                gap={0.5}
              >
                <Typography variant="body" size="m" color="text.secondary">
                  {option.description}
                </Typography>
                <MissingSingleConditionQualificationStatus
                  option={option}
                  qualification={qualification}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <ChevronRight color="disabled" />
      </Box>
    </Card>
  );
};

const MissingMultipleConditionFormOption = ({
  option,
  onClick,
}: IMissingConditionFormOptionProps) => {
  const hasMultipleQualifications = true;
  const title = option.description;

  const handleClick = (qualification?: IQualification) => {
    onClick(option.condition, qualification || option.condition.qualifications[0]);
  };

  return (
    <Card color="gray">
      <Box p={2} pb={1}>
        <Typography variant="body" size="m" color="text.primary">
          {capitalize(title)}
        </Typography>
        <Box>
          {option.condition.qualifications.map((qualification, index) => (
            <Fragment key={String(qualification.id)}>
              {index > 0 && <Divider />}
              <Box
                py={1.5}
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ cursor: 'pointer' }}
                onClick={() => handleClick(qualification)}
              >
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap={0.5}
                >
                  <Typography variant="body" size="m" color="text.secondary">
                    {qualification.long_name}
                  </Typography>
                  <MissingSingleConditionQualificationStatus
                    option={option}
                    qualification={qualification}
                  />
                </Box>
                {hasMultipleQualifications && <ChevronRight color="disabled" />}
              </Box>
            </Fragment>
          ))}
        </Box>
      </Box>
    </Card>
  );
};

const MissingConditionFormOptions = () => {
  const {
    selectedCondition,
    selectedQualification,
    options,
    selectedQualificationIndex,
    selectCondition,
  } = useMissingConditionForm();
  const qualifications = selectedQualification ? [selectedQualification] : [];

  if (!options.length) {
    return null;
  }

  return (
    <>
      {options.length > 1 && !selectedCondition ? (
        options.map((option, index) => (
          <Fragment key={index}>
            {index > 0 && <Divider title="Or" titleAlign="center" boxProps={{ py: 2 }} />}
            {option.condition.qualifications.length > 1 ? (
              <MissingMultipleConditionFormOption
                option={option}
                onClick={(_, qualification) => selectCondition(option.condition, qualification)}
              />
            ) : (
              <MissingSingleConditionFormOption
                option={option}
                onClick={() => selectCondition(option.condition)}
              />
            )}
          </Fragment>
        ))
      ) : (
        <ConditionFormFields
          name={`condition.qualifications[${selectedQualificationIndex}]`}
          condition={{
            ...selectedCondition,
            qualifications,
          }}
          withQualificationName
        />
      )}
    </>
  );
};

export default MissingConditionFormOptions;
