import {
  IJobPriceDifferentials,
  IJob,
  JobStatusEnum as JobStatus,
  IJobProfile,
} from '@medely/types';
import { InfoBadgeColor } from '@medely/ui-kit';

export const isHolidayDifferential = (differential: IJobPriceDifferentials): boolean =>
  differential.slug === 'holiday' && differential.recommendation_used;

export const historicalShiftBadgeText = ({ status }: IJob): string => {
  switch (status) {
    case JobStatus.CanceledWithFee:
      return 'Canceled';
    case JobStatus.ClockedOut:
    case JobStatus.Completed:
      return 'Completed';
    case JobStatus.Disputed:
      return 'Disputed';
    case JobStatus.HeldForDisputeReview:
      return 'In review';
    case JobStatus.LateCancellation:
    case JobStatus.NoShow:
      return 'Canceled';
    case JobStatus.MissedShift:
      return 'Missed shift';
    default:
      return '';
  }
};

export const historicalShiftBadgeColor = ({ status }: IJob): InfoBadgeColor => {
  switch (status) {
    case JobStatus.CanceledWithFee:
    case JobStatus.MissedShift:
      return 'default';
    case JobStatus.ClockedOut:
    case JobStatus.Completed:
      return 'success';
    case JobStatus.Disputed:
    case JobStatus.LateCancellation:
    case JobStatus.NoShow:
      return 'error';
    case JobStatus.HeldForDisputeReview:
      return 'warning';
    default:
      return 'default';
  }
};

export const jobBonusCents = (job: {
  total_payout_bonus_cents?: number;
  job_profile_for_account_id?: Pick<IJobProfile, 'payout_facility_bonus_cents'> | null;
}) => {
  const newBonusField = job?.job_profile_for_account_id?.payout_facility_bonus_cents;
  const oldBonusField = job?.total_payout_bonus_cents;

  return (!!newBonusField ? newBonusField : oldBonusField) ?? 0;
};
