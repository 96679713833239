import { Heading, Box, Text } from '@medely/ui-kit';
import { Link } from '@medely/web-components';
import { config } from '@src/config';

import React from 'react';

export const CancelationPolicy = () => {
  return (
    <Box>
      <Heading size="s" color="text.primary">
        Cancelation policy
      </Heading>
      <Box my={1}>
        <Text size="m">
          You can cancel a shift without penalty within the first 30 minutes of booking or more than
          24 hours before the scheduled start time.{' '}
          <Link href={config.help.cancellationsAndNoShow} external>
            Learn more
          </Link>
        </Text>
      </Box>
    </Box>
  );
};
