import { Box } from '@medely/web-components';
import { ICondition } from '@medely/types';
import React from 'react';
import QualificationFormFields, { QualificationFormFieldsArgs } from './QualificationFormFields';

type CondifitionFormFieldsArgs = Pick<QualificationFormFieldsArgs, 'name' | 'withNameLabels'> & {
  condition: ICondition;
  withQualificationName?: boolean;
};

const ConditionFormFields = ({
  name,
  condition,
  withNameLabels,
  withQualificationName,
}: CondifitionFormFieldsArgs) => (
  <Box data-testid="condition-form">
    {condition.qualifications.map((qualification) => (
      <QualificationFormFields
        key={qualification.id}
        name={name}
        qualification={qualification}
        withNameLabels={withNameLabels}
        withQualificationName={withQualificationName}
        updateForm={false}
      />
    ))}
  </Box>
);

export default ConditionFormFields;
