import React, { createContext, useContext, ReactNode, useEffect, useState } from 'react';
import useMissingConditionOptions, {
  type MissingConditionOption,
} from 'components/credentials/conditionDialogs/useMissingConditionOptions';
import {
  buildPayloadFromConditionForm,
  type ConditionFormValues,
} from 'utils/professionalQualifications';
import useProfessionalQualifications from 'hooks/useProfessionalQualifications';
import useMissingConditionFormValues from 'components/credentials/conditionDialogs/useMissingConditionFormValues';
import type { ICondition, IQualification } from '@medely/web-components/types';
import { useEditProfessionalQualification } from 'hooks/useProfessionalQualification';
import { useTrackQualification } from 'hooks/useTrackQualification';

interface IMissingConditionFormProps {
  condition: ICondition;
  children: (props: {
    condition: ICondition;
    isActionable: boolean;
    submit: (values: { condition: ConditionFormValues }) => Promise<void>;
  }) => ReactNode;
  onQualificationsResolve?: () => void;
}

type MissingConditionFormContext = {
  condition?: ICondition;
  options: MissingConditionOption[];
  selectedCondition?: ICondition;
  selectedQualification?: IQualification;
  selectedQualificationIndex: number;
  selectCondition: (condition?: ICondition, qualification?: IQualification) => void;
};

const MissingConditionFormContext = createContext<MissingConditionFormContext>({
  options: [],
  selectedQualificationIndex: 0,
  selectCondition: () => null,
});

export const useMissingConditionForm = () => useContext(MissingConditionFormContext);

const MissingConditionForm = ({
  condition,
  children,
  onQualificationsResolve,
}: IMissingConditionFormProps) => {
  const { submitProfessionalQualification } = useProfessionalQualifications();
  const { editAsync: editProfessionalQualification } = useEditProfessionalQualification();
  // it's false for now till we find a way to differenciate between single edit and multisteps form
  const [isMultiStep] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState<ICondition | undefined>(undefined);
  const [selectedQualificationIndex, setSelectedQualificationIndex] = useState<number>(0);
  const missingQualifications = useMissingConditionOptions(condition);
  const { values: conditionFormValues, findExistingQualification } =
    useMissingConditionFormValues(selectedCondition);
  const { trackQualificationStartEditing, trackQualificationFinishEditing } =
    useTrackQualification();
  const selectedQualification = selectedCondition?.qualifications?.[selectedQualificationIndex];
  const isSingleCondition = missingQualifications.length === 1;
  const isActionable = isSingleCondition || Boolean(selectedQualification);
  const isLastStep = isMultiStep
    ? selectedQualificationIndex === selectedCondition?.qualifications?.length - 1
    : true;

  const submit = async (values: {
    condition: ConditionFormValues;
    qualification_automation_id?: number;
  }) => {
    const existingPq = findExistingQualification(selectedQualification);
    const payload = buildPayloadFromConditionForm(
      values.condition,
      selectedQualificationIndex,
      true,
      values.qualification_automation_id,
    );

    if (existingPq && !['approved', 'expired'].includes(existingPq.status)) {
      await editProfessionalQualification({
        id: existingPq.id,
        ...payload,
      });
    } else {
      await submitProfessionalQualification(payload);
    }

    trackQualificationFinishEditing({
      qualificationId: selectedQualification?.id,
      saved: true,
    });

    if (isLastStep) {
      onQualificationsResolve?.();
    } else {
      setSelectedQualificationIndex((index) => index + 1);
    }
  };

  const selectCondition = (condition?: ICondition, qualification?: IQualification) => {
    if (condition) {
      const currentIndex = Math.max(0, condition.qualifications.indexOf(qualification));
      setSelectedQualificationIndex(currentIndex);
    }

    setSelectedCondition(condition);
  };

  useEffect(() => {
    if (!missingQualifications.length) {
      onQualificationsResolve?.();
    } else if (missingQualifications.length === 1) {
      setSelectedCondition(missingQualifications[0].condition);
    }
  }, [missingQualifications.length]);

  useEffect(() => {
    if (selectedQualification) {
      trackQualificationStartEditing({ qualificationId: selectedQualification.id });
    }

    return () => {
      if (selectedQualification) {
        trackQualificationFinishEditing({ qualificationId: selectedQualification.id });
      }
    };
  }, [selectedQualification]);

  return (
    <MissingConditionFormContext.Provider
      value={{
        condition,
        options: missingQualifications,
        selectedCondition,
        selectedQualification,
        selectCondition,
        selectedQualificationIndex,
      }}
    >
      {children({ condition: conditionFormValues, submit, isActionable })}
    </MissingConditionFormContext.Provider>
  );
};

export default MissingConditionForm;
