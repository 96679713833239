/* eslint-disable no-console */
import { Box, CircularProgress, Dialog, DialogProps } from '@medely/web-components';
import flow from 'lodash/flow';
import useProfessionalQualification from 'hooks/useProfessionalQualification';
import React, { ReactElement, useState } from 'react';
import { buildPayloadFromQualificationForm } from 'utils/professionalQualifications';
import titleize from 'utils/titleize';
import ProfessionalQualificationModalEditContent from './EditContent';
import ProfessionalQualificationModalViewContent from './ViewContent';
import { IProfessionalQualificationAttribute } from '@medely/types';
import AutoOpenSignedDocumentModal from './signedDocumentModal/AutoOpenSignedDocumentModal';
import SignedDocumentDialogContent from './signedDocumentModal/SignedDocumentDialogContent';
import {
  isSimpleSignedDocumentQualification,
  shouldAutoOpenHelloSign,
} from './signedDocumentModal/utils';
import { useHistory } from 'react-router';
import { isI9Qualification } from './i9Modal/utils';
import I9DialogContent from './i9Modal/I9DialogContent';
import { useTrackQualification } from 'hooks/useTrackQualification';

export type ProfessionalQualificationDialogMode = 'view' | 'replace' | 'edit';

export type ProfessionalQualificationModalProps = DialogProps & {
  isLoading?: boolean;
  initialMode?: ProfessionalQualificationDialogMode;
  professionalQualificationId: number;
};

const ProfessionalQualificationModal = ({
  isLoading: isParentLoading,
  initialMode = 'view',
  professionalQualificationId,
  onClose,
  open,
}: ProfessionalQualificationModalProps) => {
  const [mode, setMode] = useState<ProfessionalQualificationDialogMode>(initialMode);
  const { professionalQualification, isLoading, submit, edit, editAsync } =
    useProfessionalQualification(professionalQualificationId);
  const { trackQualificationStartEditing, trackQualificationFinishEditing } = useTrackQualification(
    {
      qualificationId: professionalQualification?.qualification?.id,
    },
  );

  const history = useHistory();

  const trackCloseEditMode = (saved = false) => {
    trackQualificationFinishEditing({
      saved,
    });
  };

  const changeMode = (newMode: ProfessionalQualificationDialogMode) => {
    const isLeavingViewMode = mode === 'view' && newMode !== 'view';
    const isLeavingEditMode = mode !== 'view' && newMode === 'view';

    setMode(newMode);

    if (isLeavingViewMode) {
      trackQualificationStartEditing();
    } else if (isLeavingEditMode) {
      trackCloseEditMode();
    }
  };

  const changeStateOnSuccessfulEdit = () => {
    history.replace({
      state: 'successfulEdit',
    });
  };

  const handleSuccessfulEdit = () => {
    changeStateOnSuccessfulEdit();
    trackCloseEditMode(true);
    handleClose();
  };

  const handleSuccessfulReplace = () => {
    trackCloseEditMode(true);
    handleClose();
  };

  const handleClose = () => {
    setMode('view');
    onClose();
  };

  const submitReplace = (values) => {
    const payload = buildPayloadFromQualificationForm(values);
    return submit(payload, {
      onSuccess: handleSuccessfulReplace,
    });
  };

  const submitEdit = (values) => {
    const payload = buildPayloadFromQualificationForm(values);
    edit(
      { ...payload, id: values.id },
      {
        onSuccess: handleSuccessfulEdit,
      },
    );
  };

  const handleSign = async (data: IProfessionalQualificationAttribute) => {
    return editAsync(
      {
        id: data.professional_qualification_id,
        professional_qualification: {
          qualification_id: professionalQualification.qualification.id,
          professional_qualification_attributes_attributes: [
            {
              qualification_attribute_id: data.qualification_attribute_id,
              value: data.value,
            },
          ],
        },
      },
      {
        onSuccess: changeStateOnSuccessfulEdit,
      },
    );
  };

  const COMPONENT_MAPPING: { [K in ProfessionalQualificationDialogMode]: ReactElement } = {
    view: (
      <ProfessionalQualificationModalViewContent
        professionalQualification={professionalQualification}
        setMode={changeMode}
      />
    ),
    replace: (
      <ProfessionalQualificationModalEditContent
        professionalQualification={professionalQualification}
        setMode={changeMode}
        onSubmit={submitReplace}
        isLoading={isLoading}
      />
    ),
    edit: (
      <ProfessionalQualificationModalEditContent
        professionalQualification={professionalQualification}
        setMode={changeMode}
        onSubmit={submitEdit}
        isLoading={isLoading}
        updateForm
      />
    ),
  };

  let content = COMPONENT_MAPPING[mode];

  // ProfessionalQualificationModalEditContent handles isLoading internally
  const isLoadingForm = isLoading && !['replace', 'edit'].includes(mode);

  if (isParentLoading || isLoadingForm || !professionalQualification) {
    content = (
      <Box display="flex" py={3} justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (isSimpleSignedDocumentQualification(professionalQualification?.qualification)) {
    if (shouldAutoOpenHelloSign(professionalQualification)) {
      const pqaId = professionalQualification?.professional_qualification_attributes.find(
        (pqa) => pqa.qualification_attribute.field_type === 'signed_document',
      )?.id;
      return (
        <AutoOpenSignedDocumentModal
          onClose={flow(handleClose, trackCloseEditMode)}
          onSign={handleSign}
          pqaId={pqaId}
        />
      );
    }

    if (mode === 'edit') {
      content = (
        <SignedDocumentDialogContent
          onClose={flow(handleClose, trackCloseEditMode)}
          onSign={handleSign}
          professionalQualification={professionalQualification}
        />
      );
    }
  }

  if (isI9Qualification(professionalQualification?.qualification)) {
    content = (
      <I9DialogContent
        onClose={handleClose}
        professionalQualification={professionalQualification}
      />
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={flow(handleClose, trackCloseEditMode)}
      open={open}
      title={titleize(professionalQualification?.qualification.qualification_type ?? '')}
      content={content}
    />
  );
};

export default ProfessionalQualificationModal;
