/** Copied from web/src/utils/displayHelpers.ts */

import { IJob } from '@medely/types';
import { tz } from 'moment-timezone';
import { pluralize } from './text';
import { MedelyDateTime } from '@medely/date-time-tools';

function jobTime(
  job: Partial<IJob>,
  {
    withShiftLength = true,
    scheduledShiftStyle = false,
  }: { withShiftLength?: boolean; scheduledShiftStyle?: boolean } = {},
): string | null {
  const {
    current_starts_time,
    approved_clock_in,
    current_ends_time,
    approved_clock_out,
    location,
    guaranteed_minimum_hours,
    status,
  } = job;
  if (!current_starts_time || !current_ends_time) {
    return null;
  }

  const timezone = location?.timezone_lookup || tz.guess();
  const startsTime = approved_clock_in || current_starts_time;
  const endsTime = approved_clock_out || current_ends_time;
  const start = new MedelyDateTime(startsTime, {
    tz: timezone,
  });

  const end = new MedelyDateTime(endsTime, {
    tz: timezone,
  });

  const hoursDiff = start.difference(end, 'hours').toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  const hours =
    status === 'canceled_with_fee'
      ? guaranteed_minimum_hours
      : Math.max(guaranteed_minimum_hours ?? 0, +hoursDiff);

  return scheduledShiftStyle
    ? `${start.militaryTime} - ${end.militaryTime}${
        withShiftLength ? ` (${pluralize(hours ?? 0, 'hr')})` : ``
      }`
    : `${start.militaryTime}-${end.militaryTime}${
        withShiftLength ? ` • ${pluralize(hours ?? 0, 'hr')}` : ``
      }`;
}

const DisplayHelpers = {
  job: { time: jobTime },
};

export default DisplayHelpers;
