import {
  CredentialStatus,
  IProfessionalQualification,
  IProfessionalQualificationAttribute,
  IQualification,
} from '@medely/types';
import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Typography,
} from '@medely/web-components';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { STATUS_MAP } from 'utils/credentialUtils';
import Attribute from '../attributes/Attribute';
import { getValidProfessionalQualificationAttributes } from '../utils/credentialsUtils';
import { ProfessionalQualificationDialogMode } from './ProfessionalQualificationModal';

const EDITABLE_STATUSES: CredentialStatus[] = ['rejected', 'review', 'incomplete'];

type ProfessionalQualificationModalViewContentProps = {
  professionalQualification: IProfessionalQualification;
  setMode: (mode: ProfessionalQualificationDialogMode) => void;
};

const ProfessionalQualificationModalViewContent = ({
  professionalQualification,
  setMode,
}: ProfessionalQualificationModalViewContentProps): React.ReactElement => {
  const attributes = getValidProfessionalQualificationAttributes(
    professionalQualification.professional_qualification_attributes,
  );

  const canEditOrReplace = determineCanEditOrReplace(
    professionalQualification.status,
    professionalQualification.qualification.qualification_type,
    attributes,
  );

  const newMode: { mode: ProfessionalQualificationDialogMode; label: string } =
    EDITABLE_STATUSES.includes(professionalQualification.status)
      ? { mode: 'edit', label: 'Edit' }
      : { mode: 'replace', label: 'Update' };

  return (
    <>
      <DialogContent>
        {professionalQualification.status === 'rejected' &&
          professionalQualification.credential_rejected_reason && (
            <Card
              color="error"
              sx={{ mb: 2 }}
              data-testid="credential-modal-view-content-reject-modal"
            >
              <CardContent>
                <Typography variant="body2" weight="medium">
                  Rejected
                </Typography>
                <Typography variant="body2">
                  {professionalQualification.credential_rejected_reason.label}
                </Typography>
              </CardContent>
            </Card>
          )}
        <Box mb={2} data-testid="credential-modal-view-content-box">
          <Typography
            gutterBottom
            weight="medium"
            data-testid="credential-modal-view-content-qualification-name"
          >
            {professionalQualification.qualification.long_name}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ color: STATUS_MAP[professionalQualification.status].color }}
            data-testid="credential-modal-view-content-credential-status"
          >
            {STATUS_MAP[professionalQualification.status].label}
          </Typography>
        </Box>
        {attributes.map((attribute) => (
          <Box mb={2} key={attribute.id}>
            <Typography
              weight="medium"
              gutterBottom
              data-testid="credential-modal-view-content-attribute-name"
            >
              {attribute.qualification_attribute.name}
            </Typography>
            <Attribute attribute={attribute} data-testid="attribute" />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        {canEditOrReplace && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setMode(newMode.mode)}
            data-testid="credential-modal-view-content-button"
          >
            {newMode.label}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default ProfessionalQualificationModalViewContent;

const determineCanEditOrReplace = (
  status: IProfessionalQualification['status'],
  qualification_type: IQualification['qualification_type'],
  attributes: IProfessionalQualificationAttribute[],
) => {
  const isIncomplete = status === 'incomplete';
  const isOneTimeSignature = qualification_type === 'signed_document';
  const hasEditableAttributes = !isEmpty(attributes);

  return hasEditableAttributes && (isIncomplete || !isOneTimeSignature);
};
