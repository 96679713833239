import { gql } from 'graphql-request';

export const GET_PROFESSIONAL_QUALIFICATIONS = gql`
  query ProfessionalQualifications($input: ProfessionalQualificationQueryInput) {
    professionalQualifications(input: $input) {
      id
      status
      created_at
      credential_rejected_reason {
        label
      }
      professional_qualification_attributes {
        id
        type
        value
        state
        qualification_attribute_id
        qualification_attribute {
          active
          field_type
          id
          input_source
          name
          required
          slug
        }
        professional_qualification_id
      }
      qualification {
        id
        long_name
        slug
        default_verification_title
        qualification_automations {
          id
          description
          name
          loading_text
          is_active
          qualification_automation_attributes {
            id
            qualification_attribute {
              id
              field_type
              slug
              name
              data
              input_source
              active
            }
            required
          }
        }
        qualification_type
        instructions
        conditions {
          id
          condition_documents {
            id
            name
            category
            file
          }
        }
        qualification_attributes {
          id
          name
          data
          input_source
          field_type
          active
        }
      }
    }
  }
`;

export const GET_PROFESSIONAL_QUALIFICATION = gql`
  query ProfessionalQualification($id: Int!) {
    professionalQualification(id: $id) {
      id
      status
      created_at
      credential_rejected_reason {
        label
      }
      professional_qualification_attributes {
        id
        type
        value
        state
        qualification_attribute_id
        qualification_attribute {
          id
          slug
          name
          input_source
          field_type
          active
        }
        professional_qualification_id
        metadata
      }
      qualification {
        id
        long_name
        slug
        qualification_type
        instructions
        conditions {
          id
          condition_documents {
            id
            name
            category
            file
          }
        }
        qualification_attributes {
          id
          name
          data
          input_source
          field_type
          active
        }
      }
    }
  }
`;

export const GET_PROFESSIONAL_QUALIFICATION_ATTRIBUTE = gql`
  query ProfessionalQualificationAttribute($id: Int!) {
    professionalQualificationAttribute(id: $id) {
      id
      type
      value
      state
      qualification_attribute_id
      qualification_attribute {
        id
        slug
        name
        input_source
        field_type
        active
      }
      professional_qualification_id
      metadata
    }
  }
`;
