import { type Routes } from '@medely/ui-kit';

// Key = Route name
// Value = Params type
// Optional params should use | undefined, not ?
// Currently only primitive values are supported.
// All params are query params (no path params)
export type ProRoutes = {
  Profile: never;
};

export const proRoutes: Routes<ProRoutes> = {
  Profile: {
    url: '/profile',
  },
};
