import { gql } from 'graphql-request';

export const GET_CONDITIONS = gql`
  query MissingConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      slug
      label
      payload
      condition_type
      display_category
      display_description
      condition_documents {
        id
        name
        category
        file
      }
      qualifications {
        id
        instructions
        long_name
        slug
        admin_view_only
        qualification_type
        status
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
          slug
        }
        default_verification_title
        qualification_automations {
          id
          description
          name
          loading_text
          is_active
          qualification_automation_attributes {
            id
            qualification_attribute {
              id
              field_type
              slug
              name
              data
              input_source
              active
            }
            required
          }
        }
      }
    }
  }
`;

export const GET_REQUIRED_CONDITIONS = gql`
  query RequiredConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      slug
      label
      payload
      condition_type
      display_category
      display_description
      condition_documents {
        id
        name
        category
        file
      }
      qualifications {
        id
        instructions
        long_name
        slug
        admin_view_only
        qualification_type
        status
        short_name
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
        }
      }
    }
  }
`;

export const GET_APPLICATION_CONDITIONS = gql`
  query MissingApplicationConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      label
      slug
      condition_type
      qualifications {
        id
        instructions
        long_name
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
        }
      }
    }
  }
`;

export const GET_ONBOARDING_CONDITIONS = gql`
  query MissingOnboardingConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      slug
      payload
      qualifications {
        id
        slug
        qualification_type
        qualification_attributes {
          id
          slug
        }
      }
    }
  }
`;

export const GET_EXPIRING_CONDITIONS = gql`
  query ExpiringConditions($input: ConditionQueryInput) {
    conditions(input: $input) {
      id
      slug
      label
      payload
      condition_type
      display_category
      display_description
      condition_documents {
        id
        name
        category
        file
      }
      qualifications {
        id
        instructions
        long_name
        slug
        admin_view_only
        qualification_type
        status
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
          slug
        }
      }
    }
  }
`;

export const GET_CONDITION = gql`
  query Condition($id: Int!) {
    condition(id: $id) {
      id
      slug
      label
      payload
      condition_type
      display_category
      display_description
      condition_documents {
        id
        name
        category
        file
      }
      qualifications {
        id
        instructions
        long_name
        slug
        admin_view_only
        qualification_type
        status
        qualification_attributes {
          id
          name
          field_type
          required
          data
          input_source
          active
          slug
        }
      }
    }
  }
`;
