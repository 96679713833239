import { gql } from 'graphql-request';
import {
  DEFAULT_GRAPHQL_FRAGMENT,
  type GraphQLFragment,
  type IsAgencyAccountInput,
  isAgencyAccount,
} from '../utils';

export const getTimecardQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceTimecardFragment',
      fragment: gql`
        fragment MarketplaceTimecardFragment on Timecard {
          assignment {
            ends_date
            hours_per_shift
            is_travel
            is_w2
            number_of_shifts_per_week
            payout_base_hourly_rate_cents
            primary_location {
              id
              name
              timezone_lookup
            }
            require_physical_signature
            starts_date
            track_unpaid_breaks
          }
          jobs {
            assignment {
              float_requirements
              is_w2
              primary_location {
                id
              }
            }
            breaks {
              duration_minutes
              id
              starts_time
              waived
            }
            cancellation_fee_charge_hours
            cancellation_reason_id
            created_by_account_id
            current_ends_time
            current_starts_time
            expense_adjustments {
              amount_cents
              created_at
              taxable_type
            }
            guaranteed_minimum_hours
            id
            job_billing_sums {
              total_hours
            }
            last_cancellation {
              other_reason
            }
            location {
              id
              name
              timezone_lookup
              unit
            }
            shift_type
            starts_date
            status
          }
        }
      `,
    };
  }

  return gql`
    ${fragment.fragment}

    query Timecard($id: Int!) {
      timecard(id: $id) {
        ${fragment.name}
        assignment_id
        ends_time
        id
        starts_time
        status
        submit_by
      }
    }
  `;
};

export const getTimecardsQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceTimecardsFragment',
      fragment: gql`
        fragment MarketplaceTimecardsFragment on Timecard {
          assignment {
            ends_date
            ends_time
            float_requirements
            hours_per_shift
            is_travel
            is_w2
            locations {
              id
              name
              unit
            }
            number_of_shifts_per_week
            primary_location {
              full_address
              id
              name
              timezone_lookup
            }
            require_physical_signature
            starts_date
            starts_time
            status
            track_unpaid_breaks
          }
          jobs {
            breaks {
              duration_minutes
              id
              starts_time
              waived
            }
            current_ends_time
            current_starts_time
            ends_date
            ends_time
            id
            job_billing_sums {
              total_hours
            }
            location {
              name
            }
            shift_type
            starts_date
            starts_time
            status
          }
        }
      `,
    };
  }

  return gql`
    ${fragment.fragment}

    query Timecards($input: TimecardQueryInput) {
      timecards(input: $input) {
        ${fragment.name}
        assignment_id
        ends_time
        id
        saas_company_id
        starts_time
        status
        submit_by
      }
      _timecardsMeta(input: $input) {
        count
      }
    }
  `;
};
